<template>
    <div>
        <base-header base-title="Report Status Rujukan"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                </div>
                <div class="kt-portlet__body">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="form-group row">
                                        <label class="col-md-5 col-form-label">{{ $t('common.poly') }} :</label>
                                        <div class="col-md-7">
                                            <report-tujuan-rujukan
                                                v-model="tujuanRujukan"
                                                :placeholder="'All'"
                                            ></report-tujuan-rujukan>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="form-group row">
                                        <div class="col-md-4 col-form-label">{{ $t('report.statusRujukan') }} :</div>
                                        <div class="col-md-8">
                                            <status-rujukan-select
                                                :changedValue.sync="statusRujukan"
                                                :id-select-picker="'statusRjk'"
                                                :selected-picker="statusRujukan"
                                                :is-validate="true"
                                            ></status-rujukan-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="form-group row">
                                        <label class="col-md-4 col-form-label">{{ $t('common.tanggalRujukan') }}:</label>
                                        <div class="col-md-7 pt-1">
                                            <div class="input-group input-group-sm date">
                                                <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="la la-calendar-o glyphicon-th"></i>
                                                </span>
                                                </div>
                                                <date-range-picker
                                                :changedValueStart.sync="dateStart"
                                                :changedValueEnd.sync="dateEnd"
                                                id-date-picker="filter_rangedatepicker"
                                                picker-value
                                                place-holder="DD/MM/YYYY - DD/MM/YYYY"
                                                ></date-range-picker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group row">
                                        <button
                                            type="button"
                                            @click="submitSearch()"
                                            class="btn btn-sm btn-default btn-bold btn-upper mt-1 ml-2 "
                                            >
                                            <i class="flaticon2-search"></i> {{ $t('button.search') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                                <div id="btn_export_container">
                                </div>
                        </div>


                    </div>
                    <div v-if="showLoading" class="m-auto p-5 d-flex flex-row h-100">
                        <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                        <h5 class="my-auto pl-5">Loading ...</h5>
                    </div>
                    <div v-if="isLoaded">
                        <data-tables :table="table"></data-tables>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CheckPermission from "./../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import BaseTitle from "./../../_base/BaseTitle";
import BaseHeader from "./../../_base/BaseHeader";
import DateRangePicker from "./../../_general/DateRangePicker";
import DaftarPerujuk from "./../../../constants/report-daftar-perujuk";
import DataTables from "./../../_table/DataTables";
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
import ReportTujuanRujukan from "./../../_select/Poli.vue";
import StatusRujukanSelect from "./../../_select/StatusRujukan";
import JejakEnum from "./../../../constants/jejak-enum";
import StatusRujukan from "./../../../constants/status-rujukan-enum";
const ReportRepository = RepositoryFactory.get("report");
export default {
    components: {
        BaseHeader,
        BaseTitle,
        DateRangePicker,
        DataTables,
        ReportTujuanRujukan,
        StatusRujukanSelect
    },
    data() {
        var vx = this;
        return {
            headTitle: "Report Status Rujukan",
            dateStart: null,
            dateEnd: null,
            tujuanRujukan:null,
            statusRujukan:null,
            showLoading:false,
            isLoaded:false,
            jejakEnum: JejakEnum,
            statusRujukanEnum: StatusRujukan,
            tableName: "table_status_rujukan",
            mainTitle: "Laporan Status Rujukan",
            subTitle: "",
            table: {
                tableName: "table_status_rujukan",
                dateFormat: "dd-M-yyyy",
                tableDataResult: [],
                tableColumnDefs: [
                    {
                        defaultContent: " ",
                        targets: "_all"
                    },
                    {
                        targets: 0,
                        searchable: false,
                        orderable: false,
                        render: (data, type, row, meta) => {
                            return meta.row + 1;
                        }
                    },
                    {
                        targets: 2,
                        render: (data, type, row, meta) => {
                            const jejak = vx.findTimeStamp(data, 'enumStage', vx.jejakEnum.PASIEN_BELUM_MASUK)
                            const date = vx.formatDate(jejak.timestamp , "DD-MMM-YYYY HH:mm");
                            return date;
                        }
                    },
                    {
                        targets: 4,
                        render: (data, type, row, meta) => {
                            if(data){
                                const firstNama = (data.firstName) ? data.firstName : '';
                                const lastNama = (data.lastName) ? data.lastName : '';
                                return data.firstName + ' ' + data.lastName;
                            }
                            return null;
                        }
                    },
                    {
                        targets: 5,
                        render: (data, type, row, meta) => {
                            return (data && data.alamatPerujuks)
                                ? data.alamatPerujuks.map( s => s.nama).join(' - ')
                                : null;
                        }
                    },
                    {
                        targets: 7,
                        render: (data, type, row, meta) => {
                            const date = vx.formatDate(data);
                            return date;
                        }
                    },
                    {
                        targets: 8,
                        render: (data, type, row, meta) => {
                            return moment().diff(moment(data), 'years');
                        }
                    },
                    {
                        targets: 14,
                        className: 'text-center',
                        render: (data, type, row, meta) => {
                            if (
                                data === vx.jejakEnum.PASIEN_DATANG ||
                                data === vx.jejakEnum.DALAM_PERAWATAN ||
                                data === vx.jejakEnum.PASIEN_PULANG ||
                                data === vx.jejakEnum.DALAM_PROSES_PEMBAYARAN ||
                                data === vx.jejakEnum.SUDAH_TERBAYARKAN ||
                                data === vx.jejakEnum.CLOSED
                            ) {
                                return vx.$i18n.t("EnumStage.DITERIMA");
                            } else if (data === vx.jejakEnum.DIRUJUK_KETEMPAT_LAIN) {
                                return vx.$i18n.t("EnumStage.DIRUJUK_KETEMPAT_LAIN");
                            } else if (data === vx.jejakEnum.PASIEN_BELUM_MASUK) {
                                return vx.$i18n.t("EnumStage.PASIEN_BELUM_MASUK");
                            } else {
                                return vx.$i18n.t("EnumStage.TIDAK_JADI_DATANG");
                            }
                        }
                    },
                    {
                        targets: 16,
                        visible: false
                    },
                ],
                buttons:[
                    {
                        extend: 'excel',
                        title: "Laporan Status Rujukan",
                        messageTop: vx.subTitle,
                        exportOptions: {
                            columns: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
                        },
                    },
                    {
                        extend: 'pdfHtml5',
                        orientation: 'landscape',
                        pageSize: 'A4',
                        title: "Laporan Status Rujukan",
                        exportOptions: {
                            columns: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
                        },
                        customize( doc ) {
                            doc.defaultStyle.fontSize = 7;
                            doc.styles.tableHeader.fontSize = 7;
                            doc.content.splice( 1, 0,
                                {
                                    text: [
                                        {text : "Poli : ", bold: true},
                                        {text : (vx.tujuanRujukan !== null) ? vx.tujuanRujukan.nama : 'Semua Poli'},
                                        {text : "\n"},
                                        {text : "Status : ", bold: true},
                                        {text : (vx.statusRujukan !== vx.statusRujukanEnum.ALL) ? vx.statusRujukan : 'DITERIMA, PASIEN BELUM MASUK, DIRUJUK KE TEMPAT LAIN, DAN TIDAK JADI DATANG '},
                                        {text : "\n"},
                                        {text : "Periode     : ", bold: true},
                                        {text : vx.formatDate(vx.dateStart) + " - " + vx.formatDate(vx.dateEnd) +"\n \n"}
                                    ],
                                    alignment: 'left'
                                }
                            );
                        }
                    },
                    {
                        extend: 'print',
                        exportOptions: {
                            // columns: ':visible'  < ini untuk opsi print kalau yang mw di print hanya yg view doang
                            columns: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
                        },
                        title: 'Laporan Status Rujukan ',
                        header: true,
                        footer: true,
                        text: 'Print Laporan',
                        titleAttr: 'Print',
                        customize: win => {
                            $(win.document.body).css('font-size', '12pt');
                            $(win.document.body)
                                .find('table')
                                .addClass('compact')
                                .css('font-size', 'inherit');
                             $(win.document.body)
                                .find('h1')
                                .after(
                                    `<div class="row  mt-5 mb-3 mx-0 px-0">
                                        <div class="col-2 text-left">
                                            <div class="row"><h5>Poli :</h5></div>
                                            <div class="row"><h5>Status : </h5></div>
                                            <div class="row"><h5>Periode : </h5></div>
                                        </div>
                                        <div class="col-5 text-left">
                                            <div class="row"><h5>: ${(vx.tujuanRujukan !== null) ? vx.tujuanRujukan.nama : 'Semua Poli'}</h5></div>
                                            <div class="row"><h5>: ${(vx.statusRujukan !== vx.statusRujukanEnum.ALL) ? vx.statusRujukan : 'DITERIMA, PASIEN BELUM MASUK, DIRUJUK KE TEMPAT LAIN, DAN TIDAK JADI DATANG '}</h5></div>
                                            <div class="row"><h5>: ` + vx.formatDate(vx.dateStart) + ` - ` + vx.formatDate(vx.dateEnd) + `
                                        </div>
                                     </div>
                                    `
                                );
                            $(win.document.body)
                                .find('h1')
                                .addClass('text-center mt-3 mb-5');
                            const css = '@page { size: landscape; }',
                                head = win.document.head || win.document.getElementsByTagName('head')[0],
                                style = win.document.createElement('style');
                            style.type = 'text/css';
                            style.media = 'print';
                            if (style.styleSheet) {
                                style.styleSheet.cssText = css;
                            } else {
                                style.appendChild(win.document.createTextNode(css));
                            }
                            head.appendChild(style);
                        }
                    }
                ],
                ordering:false,
                tableColumns: [
                    {
                        title: "No",
                        data: "id"
                    },
                    {
                        title: "No Rujukan",
                        data: "id"
                    },
                    {
                        title: "Tanggal Rujukan",
                        data: "jejaks"
                    },
                    {
                        title: "Kode Perujuk",
                        data: function(row, type) {
                            if(row.perujuk) {
                                if(row.perujuk.id){
                                    return row.perujuk.id
                                }
                            }
                            return ''
                            }
                    },
                    {
                        title: "Nama Perujuk",
                        data: "perujuk"
                    },
                    {
                        title: "Nama Faskes",
                        data: "perujuk"
                    },
                    {
                        title: "Nama Pasien",
                        data: "nama"
                    },
                    {
                        title: "Tgl Lahir",
                        data: "tanggalLahir"
                    },
                    {
                        title: "Umur",
                        data: "tanggalLahir"
                    },
                    {
                        title: "Medical Record",
                        data: "medicalRecordNo"
                    },
                    {
                        title: "Nomor Registrasi",
                        data: "nomorRegistrasi"
                    },
                    {
                        title: "Tujuan Rujukan",
                        data: function(row, type) {
                            if(row.tujuanRujukan) {
                                if(row.tujuanRujukan.nama){
                                    return row.tujuanRujukan.nama
                                }
                            }
                            return ''
                            }
                    },
                    {
                        title: "Penjamin",
                         data: function(row, type) {
                            if(row.jenisPenjamin) {
                                if(row.jenisPenjamin.nama){
                                    return row.jenisPenjamin.nama
                                }
                            }
                            return ''
                            }
                    },
                    {
                        title: "Catatan Perujuk",
                        data: "catatan"
                    },
                    {
                        title: "Status",
                        data: "jejakTerakhirStage"
                    },
                    {
                        title: 'Alasan',
                        defaultContent: ''
                    },
                    {
                        title:"Actions",
                        data:"id"
                    }
                ],
                tableFilter: [
                    {
                        title: "No Rujukan",
                        type: "text"
                    },
                    {
                        title: "Tanggal Rujukan",
                        type: "datepicker"
                    },
                    {
                        title: "Kode Perujuk",
                        type: "text"
                    },
                    {
                        title: "Nama Perujuk",
                        type: "text"
                    },
                    {
                        title: "Nama Faskes",
                        type: "text"
                    },
                    {
                        title: "Nama Pasien",
                        type: "text"
                    },
                    {
                        title: "Tgl Lahir",
                        type: "datepicker"
                    },
                    {
                        title: "Umur",
                        type: "text"
                    },
                    {
                        title: "Medical Record",
                        type: "text"
                    },
                    {
                        title: "Nomor Registrasi",
                        type: "text"
                    },
                    {
                        title: "Tujuan Rujukan",
                        type: "combobox"
                    },
                    {
                        title: "Penjamin",
                        type: "combobox"
                    },
                    {
                        title: "Catatan Perujuk",
                        type: "text"
                    },
                    {
                        title: "Status",
                        type: "combobox4",
                        field: ["EnumStage.DITERIMA","EnumStage.DIRUJUK_KETEMPAT_LAIN","EnumStage.PASIEN_BELUM_MASUK","EnumStage.TIDAK_JADI_DATANG" ]
                    },
                    {
                        title: 'Alasan',
                        type: "text"
                    },
                    {
                        title: "Actions",
                        type: "actions"
                    }
                ]
            }
        }
    },
    provide() {
        return {
            $validator: this.$validator
        };
    },
    methods: {
        findTimeStamp: function(array, searchKey, searchValue) {
            const sortArray = array.sort((a,b) => moment(b.timestamp) - moment(a.timestamp));
            const index = sortArray.findIndex(x => x[searchKey] === searchValue);
            if (index >= 0) {
                return sortArray[index];
            } else {
                return null;
            }
        },
        formatDate: function (data , format = "DD-MMM-YYYY") {
            var momentDate = moment(data);
            return(momentDate.format(format));
        },
        submitSearch: function () {
            var vx = this;
            this.$validator.validateAll().then(result => {
                if (result) {
                    vx.showLoading = true;
                    vx.isLoaded = false;
                    vx.subTitle = '';
                    vx.subTitle = (vx.tujuanRujukan !== null) ? vx.subTitle + ' Poli ' + vx.tujuanRujukan.nama + ' , ' : 'Semua Poli , ';
                    vx.subTitle = (vx.statusRujukan !== vx.statusRujukanEnum.ALL) ? vx.subTitle + 'Status ' + vx.statusRujukan + ' , ' : vx.subTitle +
                                  'Status DITERIMA, PASIEN BELUM MASUK, DIRUJUK KE TEMPAT LAIN, DAN TIDAK JADI DATANG ';
                    vx.subTitle = vx.subTitle +'Periode ' +vx.formatDate(vx.dateStart) +' - ' +vx.formatDate(vx.dateEnd);
                    vx.table.buttons[0].messageTop = vx.subTitle;
                    vx.fetch();
                }
            })
        },
        async fetch(resolve) {
                let status = [];
                if (this.statusRujukan === this.statusRujukanEnum.DITERIMA) {
                    status = [
                        this.jejakEnum.PASIEN_DATANG,
                        this.jejakEnum.DALAM_PERAWATAN,
                        this.jejakEnum.PASIEN_PULANG,
                        this.jejakEnum.DALAM_PROSES_PEMBAYARAN,
                        this.jejakEnum.SUDAH_TERBAYARKAN,
                        this.jejakEnum.CLOSED
                    ];
                } else if (this.statusRujukan === this.statusRujukanEnum.PASIEN_BELUM_MASUK) {
                    status = [this.jejakEnum.PASIEN_BELUM_MASUK];
                } else if (this.statusRujukan === this.statusRujukanEnum.DIRUJUK_KETEMPAT_LAIN) {
                    status = [this.jejakEnum.DIRUJUK_KETEMPAT_LAIN];
                } else if (this.statusRujukan === this.statusRujukanEnum.TIDAK_JADI_DATANG) {
                    status = [this.jejakEnum.TIDAK_JADI_DATANG];
                } else {
                    status = [];
                }
                var params = {
                    jejaksStartDate: this.dateStart,
                    jejaksEndDate: this.dateEnd,
                    jejaksEnumStages: [this.jejakEnum.PASIEN_BELUM_MASUK],
                    jejakTerakhirStages: status,
                };
                if(this.tujuanRujukan != null) {
                    params['tujuanRujukanId'] = this.tujuanRujukan ? this.tujuanRujukan.id: ''
                }
                new Promise((resolve, reject) => {
                    ReportRepository.reportReferral(params, resolve, reject );
                })
                .then(response => {
                    const data = this.constructData(response.data);
                    if (this.isLoaded) {
                        var datatable = $("#" + this.tableName).DataTable();
                        datatable.clear();
                        datatable.rows.add(data);
                        datatable.draw("full-hold");
                        if(resolve !== undefined) {
                            resolve(data);
                        }
                    } else {
                        this.table.tableDataResult = data;
                        this.showLoading = false;
                        this.isLoaded = true;
                    }
                }).catch(error => {
                    var msg = (error.message) ? error.message : error;
                    this.showToastError('Status Rujukan : ' + msg);
                })

        },
        constructData: function (data) {
            let temp = [];
            data.forEach(transaksiReferral => {
                const jejak = this.findTimeStamp(transaksiReferral.jejaks, 'enumStage', this.jejakEnum.PASIEN_BELUM_MASUK)
                const tanggalRujuk = moment(jejak.timestamp).format('YYYY-MM-DD');
                const tanggalAkhirRujuk = moment(this.dateEnd).format('YYYY-MM-DD');
                // if (tanggalAkhirRujuk.isSameOrAfter(tanggalRujuk)) {
                //     temp.push(transaksiReferral);
                // }
                if (tanggalAkhirRujuk >= tanggalRujuk) {
                    temp.push(transaksiReferral);
                }
            });
            return temp;
        },
        showToastError: function(message) {
            toastr.error(message);
        }
    },
    created() {

    }
}
</script>
