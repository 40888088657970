<template>
  <div class="kt-spinner kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input">
    <div
      class="dropdown bootstrap-select show-tick form-control"
      :class="{'is-invalid': errors.has('selectedStatusRujukan') }"
    >
      <select
        @change="changedSelectPicker"
        class="form-control"
        id="status_rujukan_select"
        v-model="selected"
        :value="selectedValue"
        data-size="5"
        :disabled="disabledSelect"
        v-validate="isValidate ? 'required' : ''"
        name="selectedStatusRujukan"
        data-vv-as="Status Rujukan"
        :class="{'is-invalid': errors.has('selectedStatusRujukan') }"
      >
        <option v-for="value in statusRujukanEnum" :value="value" :key="value">{{  $t('EnumStage.'+value) }}</option>
      </select>
    </div>
    <div
      v-show="errors.first('selectedStatusRujukan')"
      class="invalid-feedback"
    >{{ errors.first('selectedStatusRujukan') }}</div>
  </div>
</template>

<script>

import statusRujukanEnum from '../../constants/status-rujukan-enum';

export default {
  inject: ["$validator"],
  data() {
    return {
      dataResponse: null,
      selected: null,
      disabledSelect: true,
      statusRujukanEnum: statusRujukanEnum
    };
  },
  props: {
    selectedPicker: {},
    isValidate: { type: Boolean }
  },
  computed: {
    selectedValue() {
      this.selected = this.selectedPicker;
    }
  },
  methods: {
    getSelectPicker: function() {
      this.disabledSelect = false;
      $("#status_rujukan_select").selectpicker();
      this.$nextTick(() => {
        $("#status_rujukan_select").selectpicker("refresh");
      });
    },
    changedSelectPicker: function() {
      this.$emit("update:changedValue", this.selected);
    }
  },
  mounted() {
    this.getSelectPicker();
  }
};
</script>
